<template>
    <!-- <transition-group name="fade" tag="div"> -->
    <div style="background-color: white; height: 90%; width: 100%; overflow-x: auto;">
        <!-- MixChart 컴포넌트 사용 -->
        <!-- <div v-for="(div, index) in divs" :key="index" @click="selectCurDiv(index)" class="animated-div">
            <div class="chart-title"> 
                <span>
                    {{ index }} > {{ div.cond.timeDsvn }} {{ div.cond.fromDate }} ~ {{ div.cond.toDate }} 
                </span>
                <button class="status-icon" @click.stop="addNextDiv(index + 1)">Add New ({{ index + 1}})</button>
            </div>

            <div class="chart-area" >
                <MixChart style="height:100%" :chartData="div.result.data" :options="div.result.option" class="chart" />
            </div>
        </div> -->
        <h1>Test Page</h1>
        <!-- {{ nodeData }} -->
        <!-- <org-chart :value="nodeData" :collapsible="true" class="company" selectionMode="single"
            :selectionKeys.sync="selection" @node-select="onNodeSelect" @node-unselect="onNodeUnselect"
            @node-collapse="onNodeCollapse" @node-expand="onNodeExpand">
            <template #default="slotProps">
                <p>{{ slotProps.node.data.label }}</p>
                <p>{{ slotProps.node.data.name }}</p>
            </template>
</org-chart> -->
        <!-- <v-chart :options="polar" /> -->
        <div ref="sankeyChart" style="width: 70%; height: 800px;"></div>
    </div>
    <!-- </transition-group> -->

</template>

<script>
// import moment from 'moment';
import backEndApi from '@src/api/backEndApi';
import * as echarts from 'echarts';
// import { generateColorGradient } from "../system-code2/lib/colorGradient";
// import MixChart from '@src/components/vue-chartjs/NewMixChart'; 

export default {
    components: {
        // MixChart,
    },
    // props: ['ptIdx'],
    async created() {
        // await this.getData();
    },
    async mounted() {
        this.renderChart();
        // 기본 Chart Div 추가
        // this.addNextDiv(0);
    },
    beforDestroyed() { },
    data() {
        return {
            // conds: [
            //     { ptIdxs: '', timeDsvn: 'MONTH', fromDate: '2023-01', toDate: '2024-12' },
            //     { ptIdxs: '', timeDsvn: 'DAY', fromDate: '2023-12-01', toDate: '2023-12-31' },
            //     { ptIdxs: '', timeDsvn: 'HOUR', fromDate: '2023-12-01T00', toDate: '2023-12-01T23' },
            //     { ptIdxs: '', timeDsvn: 'QUARTER', fromDate: '2023-12-01T00:00', toDate: '2023-12-01T23:59' },
            //     { ptIdxs: '', timeDsvn: 'MIN', fromDate: '2023-12-01T00:00', toDate: '2023-12-01T23:59' },
            // ],

            // divs: [],
            nodeData: {},
            selection: {},
            // data1: {
            //     key: '0',
            //     type: 'person',
            //     styleClass: 'p-person',
            //     data: { label: 'CEO', name: 'Walter White', avatar: 'walter.jpg' },
            //     children: [
            //         {
            //             key: '0_0',
            //             type: 'person',
            //             styleClass: 'p-person',
            //             data: { label: 'CFO', name: 'Saul Goodman', avatar: 'saul.jpg' },
            //             children: [{
            //                 key: '0_0_0',
            //                 data: { label: 'Tax' },
            //                 selectable: false,
            //                 styleClass: 'department-cfo'
            //             },
            //             {
            //                 key: '0_0_1',
            //                 data: { label: 'Legal' },
            //                 selectable: false,
            //                 styleClass: 'department-cfo'
            //             }],
            //         },
            //         {
            //             key: '0_1',
            //             type: 'person',
            //             styleClass: 'p-person',
            //             data: { label: 'COO', name: 'Mike E.', avatar: 'mike.jpg' },
            //             children: [{
            //                 key: '0_1_0',
            //                 data: { label: 'Operations' },
            //                 selectable: false,
            //                 styleClass: 'department-coo'
            //             }]
            //         },
            //         {
            //             key: '0_2',
            //             type: 'person',
            //             styleClass: 'p-person',
            //             data: { label: 'CTO', name: 'Jesse Pinkman', avatar: 'jesse.jpg' },
            //             children: [{
            //                 key: '0_2_0',
            //                 data: { label: 'Development' },
            //                 selectable: false,
            //                 styleClass: 'department-cto',
            //                 children: [{
            //                     key: '0_2_0_0',
            //                     data: { label: 'Analysis' },
            //                     selectable: false,
            //                     styleClass: 'department-cto'
            //                 },
            //                 {
            //                     key: '0_2_0_1',
            //                     data: { label: 'Front End' },
            //                     selectable: false,
            //                     styleClass: 'department-cto'
            //                 },
            //                 {
            //                     key: '0_2_0_2',
            //                     data: { label: 'Back End' },
            //                     selectable: false,
            //                     styleClass: 'department-cto'
            //                 }]
            //             },
            //             {
            //                 key: '0_2_1',
            //                 data: { label: 'QA' },
            //                 selectable: false,
            //                 styleClass: 'department-cto'
            //             },
            //             {
            //                 key: '0_2_2',
            //                 data: { label: 'R&D' },
            //                 selectable: false,
            //                 styleClass: 'department-cto'
            //             }]
            //         }
            //     ]
            // },
        };
    },
    computed: {},
    watch: {},
    methods: {
        renderChart() {
            let chart = echarts.init(this.$refs.sankeyChart);

            let option = {
                title: {
                    text: 'title'
                },
                tooltip: {
                    trigger: 'item',
                    triggerOn: 'mousemove'
                },
                series: [
                    {
                        type: 'sankey',
                        "data": [
                            {
                                "name": "수요처별 부하(elec)",
                                "type": "node"
                            },
                            {
                                "name": "본원7F 부하(elec)",
                                "type": "node"
                            },
                            {
                                "name": "본원7F-냉방(elec)",
                                "type": "node"
                            },
                            {
                                "name": "Cool",
                                "type": "engUseType"
                            },
                            {
                                "name": "본원7F-조명(elec)",
                                "type": "node"
                            },
                            {
                                "name": "Light",
                                "type": "engUseType"
                            },
                            {
                                "name": "본원7F-전열(elec)",
                                "type": "node"
                            },
                            {
                                "name": "Appl",
                                "type": "engUseType"
                            },
                            {
                                "name": "본원8F 부하(elec)",
                                "type": "node"
                            },
                            {
                                "name": "본원8F-조명(elec)",
                                "type": "node"
                            },
                            {
                                "name": "본원8F-냉방(elec)",
                                "type": "node"
                            },
                            {
                                "name": "본원GHP-냉방(elec)",
                                "type": "node"
                            },
                            {
                                "name": "수요처별 부하(gas)",
                                "type": "node"
                            },
                            {
                                "name": "본원8F 부하(gas)",
                                "type": "node"
                            },
                            {
                                "name": "본원GHP-냉방(gas)",
                                "type": "node"
                            },
                            {
                                "name": "구내식당(gas)",
                                "type": "node"
                            },
                            {
                                "name": "NA",
                                "type": "engUseType"
                            }
                        ],
                        "links": [
                            {
                                "source": "수요처별 부하(elec)",
                                "target": "본원7F 부하(elec)",
                                "value": 0.3,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원7F 부하(elec)",
                                "target": "본원7F-냉방(elec)",
                                "value": 0.1,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원7F-냉방(elec)",
                                "target": "Cool",
                                "value": 0.1
                            },
                            {
                                "source": "본원7F 부하(elec)",
                                "target": "본원7F-조명(elec)",
                                "value": 0.1,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원7F-조명(elec)",
                                "target": "Light",
                                "value": 0.1
                            },
                            {
                                "source": "본원7F 부하(elec)",
                                "target": "본원7F-전열(elec)",
                                "value": 0.1,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원7F-전열(elec)",
                                "target": "Appl",
                                "value": 0.1
                            },
                            {
                                "source": "수요처별 부하(elec)",
                                "target": "본원8F 부하(elec)",
                                "value": 0.3,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원8F 부하(elec)",
                                "target": "본원8F-조명(elec)",
                                "value": 0.1,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원8F-조명(elec)",
                                "target": "Light",
                                "value": 0.1
                            },
                            {
                                "source": "본원8F 부하(elec)",
                                "target": "본원8F-냉방(elec)",
                                "value": 0.1,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원8F-냉방(elec)",
                                "target": "Cool",
                                "value": 0.1
                            },
                            {
                                "source": "본원8F 부하(elec)",
                                "target": "본원GHP-냉방(elec)",
                                "value": 0.1,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원GHP-냉방(elec)",
                                "target": "Cool",
                                "value": 0.1
                            },
                            {
                                "source": "수요처별 부하(gas)",
                                "target": "본원8F 부하(gas)",
                                "value": 1.304,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원8F 부하(gas)",
                                "target": "본원GHP-냉방(gas)",
                                "value": 1.197,
                                "unit": "kWh"
                            },
                            {
                                "source": "본원GHP-냉방(gas)",
                                "target": "Cool",
                                "value": 1.197
                            },
                            {
                                "source": "본원8F 부하(gas)",
                                "target": "구내식당(gas)",
                                "value": 0.108,
                                "unit": "kWh"
                            },
                            {
                                "source": "구내식당(gas)",
                                "target": "NA",
                                "value": 0.108
                            }
                        ],
                        emphasis: {
                            focus: 'adjacency'
                        },
                        lineStyle: {
                            color: 'source',
                            curveness: 0.5
                        }
                    }
                ]
            };

            chart.setOption(option);

            window.addEventListener("resize", () => {
                chart.resize();
            });
        },

        onNodeSelect(node) {
            // this.$toast.add({ severity: 'success', summary: 'Node Selected', detail: node.data.label, life: 3000 });
            this.alertNoti(node.data.label)
        },
        onNodeUnselect(node) {
            // this.$toast.add({ severity: 'success', summary: 'Node Unselected', detail: node.data.label, life: 3000 });
            this.alertNoti(node.data.label)
        },
        onNodeExpand(node) {
            // this.$toast.add({ severity: 'success', summary: 'Node Expanded', detail: node.data.label, life: 3000 });
            this.alertNoti(node.data.label)
        },
        onNodeCollapse(node) {
            // this.$toast.add({ severity: 'success', summary: 'Node Collapsed', detail: node.data.label, life: 3000 });
            this.alertNoti(node.data.label)
        },
        async getData() {
            try {
                let result = await backEndApi.sysNode.getSysNodeTree(159)
                if (result.status == 200) {
                    this.nodeData = {
                        key: result.data[0].sysNodeIdx,
                        type: result.data[0].nodeType,
                        styleClass: '',
                        data: { label: result.data[0].nodeType, name: result.data[0].text },
                        children: [],
                    }
                    let child = await this.getCustomOrgChartData(result.data[0].children);
                    this.nodeData.children = child;
                }
            } catch (e) {
                console.error(e)
            }
        },
        getCustomOrgChartData(data) {
            return data.map(item => {
                const { sysNodeIdx, text, nodeType, children } = item;
                return {
                    key: sysNodeIdx,
                    type: nodeType,
                    styleClass: '',
                    data: { label: nodeType, name: text },
                    children: children ? this.getCustomOrgChartData(children) : [],
                }
            })
        },
        // selectCurDiv(index) {
        //     console.log('selectCurDiv. index:', index);
        //     // 현재 인덱스보다 뒤에 있는 모든 div를 제거
        //     this.divs = this.divs.slice(0, index + 1);
        // },
        // async addNextDiv (index) {
        //     console.log('addNextDiv . index:', index);

        //     if (index >= this.conds.length) return;

        //     // 현재 div 이후에 새로운 div 추가
        //     this.divs = this.divs.slice(0, index);

        //     const nextCond = this.conds[index];
        //     const result = await this.getTrendData(nextCond);
        //     const chartData = this.makeChartData(result);

        //     const newDiv = {
        //         cond: nextCond,
        //         result: chartData[0],
        //     };

        //     console.log('newDiv :', newDiv);
        //     this.divs.push(newDiv);
        // },
        // async getTrendData(cond) {
        //     try {
        //         cond.ptIdxs = 485;

        //         console.log('API call params : ', cond);

        //         let result = await backEndApi.dataAnalysis.pointSummaryData(cond);
        //         if (result.status == 200) {
        //             return result.data;
        //             // result.data.forEach(item => item.updDt = moment(item.updDt).format('YYYY-MM-DD HH:mm:ss'));
        //             // this.rltdLiveStatus = result.data;
        //         }
        //     } catch (err) {
        //         console.error(err);
        //     }
        // },
        // makeChartData(baseData) {
        //     const charts = [];

        //     const headers = baseData.headers;
        //     const results = baseData.results;

        //     const startColor = '#000000';
        //     const endColor = '#0000FF';
        //     let backgroundColors = [];
        //     if (headers.length > 1) {
        //         backgroundColors = generateColorGradient(startColor, endColor, headers.length - 1)
        //     } else {
        //         backgroundColors[0] = '#A0A0A0';
        //     }

        //     headers.forEach((header, headerIndex) => {

        //         const backgroundColor = backgroundColors[headerIndex];  // 단일 색상

        //         const unit = this.$store.state.units.find(unit => unit.value == header.unitSmallCode)?.text ?? '-';
        //         const borderColor = backgroundColor + '99';
        //         const dispText = header.ptName ?? header.ptAddr;

        //         charts.push({
        //             key: `${header.ptAddr}`,
        //             flag: true,
        //             data: {
        //                 labels: results.map(result => {
        //                     const regDt = result.regDt;
        //                     const hour = regDt.substring(8, 10);
        //                     return `${hour}:00`;
        //                 }),
        //                 datasets: [
        //                     {
        //                         key: dispText,
        //                         propCode: dispText,
        //                         type: 'line',
        //                         lineTension: 0.5,
        //                         borderWidth: 1.3,
        //                         fill: true,
        //                         label: dispText + ` (${unit})`,
        //                         pointRadius: 3,
        //                         showLine: true,
        //                         interpolate: true,
        //                         borderColor,
        //                         backgroundColor: backgroundColor + '44',
        //                         data: results.map(result => result[header.ptIdx] === undefined ? null : result[header.ptIdx])
        //                     }
        //                 ]
        //             },
        //             option: {
        //                 responsive: true,
        //                 maintainAspectRatio: false,
        //                 animation: {
        //                     duration: 1000,
        //                     easing: "easeOutQuart",
        //                 },
        //                 legend: {
        //                     display: true,
        //                     align: "end",
        //                     labels: {
        //                         boxWidth: 12,
        //                         fontColor: backgroundColor + 'FF',
        //                         fontSize: 10
        //                     }
        //                 },
        //                 title: {
        //                     display: false,
        //                     align: "start",
        //                     position: "top",
        //                     text: dispText,
        //                     fontSize: 12,
        //                     fontColor: "#000000",
        //                 },
        //                 layout: { padding: { left: 0, right: 0, top: 0, bottom: 0 } },
        //                 hoverMode: "index",
        //                 hover: {
        //                     mode: 'index',
        //                     intersect: false,
        //                 },
        //                 scales: {
        //                     xAxes: [
        //                         {
        //                             id: "x-axis-0",
        //                             stacked: false,
        //                             offset: true,
        //                             ticks: {
        //                                 maxTicksLimit: 10,
        //                                 autoSkip: true,
        //                                 fontColor: "#000000",
        //                                 fontSize: 10,
        //                                 maxRotation: 0,
        //                             },
        //                             gridLines: {
        //                                 display: true,
        //                                 color: backgroundColor + '44',
        //                                 borderDash: [2, 1],
        //                             },
        //                         },
        //                     ],
        //                     yAxes: [
        //                         {
        //                             id: "y-axis-0",
        //                             display: true,
        //                             position: "left",
        //                             stacked: false,
        //                             ticks: {
        //                                 maxTicksLimit: 5,
        //                                 beginAtZero: true,
        //                                 fontColor: "#000000",
        //                                 fontSize: 10,
        //                             },
        //                             gridLines: {
        //                                 display: true,
        //                                 color: backgroundColor + '44',
        //                                 borderDash: [2, 1],
        //                             },
        //                         },
        //                     ],
        //                 },
        //                 plugins: {
        //                     zoom: {
        //                         pan: {
        //                             enabled: true,
        //                             mode: 'xy'
        //                         },
        //                         zoom: {
        //                             enabled: true,
        //                             drag: true,
        //                             mode: 'xy'
        //                         }
        //                     }
        //                 }
        //             }
        //         });
        //     });

        //     return charts;
        // }
    },
}
</script>

<style scoped>
/* :deep(.p-organizationchart) {
    
    .p-person {
        padding: 0;
        border: 0 none;
    }

    .node-header,
    .node-content {
        padding: .5em .7rem;
    }

    .node-header {
        background-color: #495ebb;
        color: #ffffff;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    .node-content {
        text-align: center;
        border: 1px solid #495ebb;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .department-cfo {
        background-color: #7247bc;
        color: #ffffff;
    }

    .department-coo {
        background-color: #a534b6;
        color: #ffffff;
    }

    .department-cto {
        background-color: #e9286f;
        color: #ffffff;
    }
} */

/* 차트 영역을 고려한 높이 설정 */
/* .animated-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 250px;
    margin: 10px 0;
    background-color: lightblue;
    overflow: hidden;
    cursor: pointer;
    position: relative;
}

.chart-title {
    height: 40px;
    width: 100%;
    flex-grow: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    background-color: #f1f1f1;

    padding: 8px 16px 8px 16px;
    margin-bottom: 0px !important;
    font-weight: bold;

    border-radius: 6px 6px 0px 0px;
    border: solid #ececec 1px;
    border-bottom: 1px solid #ccc;
}

.chart-area {
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    padding: 15px;
    border-radius: 0px 0px 6px 6px;
    border: solid #ececec 1px;
}

.chart {
    width: 100%;
    height: 100%;
}

.status-icon {
    font-size: 12px;
    color: white;
    background-color: #FF6D00;
    border: solid #FF6D00 1px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.05rem;
    margin-bottom: 0.05rem;
    transition: background-color 0.5s;
    height: 1.8rem;
}

.status-icon:hover {
    color: white;
    background-color: #FF6D00;
}

.status-icon.active {
    color: #ECEFF1;
    background-color: #546E7A;
} */
</style>